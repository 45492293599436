import { AuthType } from '@helpers/AuthClient';
import VerifyEmailView from '@views/VerifyEmailView';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

const VerifyEmailPage: FC<PageProps> = () => {
  return <VerifyEmailView authType={AuthType.Web} />;
};

export default VerifyEmailPage;
